:root {
    --main-color: #111b30;
    --border-color: #f3f3f3;
    --highlight-color: #ffff00;
}

body {
    font-family: Inter, Helvetica, sans-serif;
    margin: 0rem auto 0rem auto;
    max-width: 640px;
    width: 100%;
}

*::selection {
    background-color: var(--highlight-color);
}

p a,
p a:visited {
    color: var(--main-color);
    text-decoration: none;
}

p a:hover {
    text-decoration: underline;
    text-decoration-color: var(--highlight-color);
}

hr {
    border: 0.06rem solid var(--border-color);
}

#content {
    margin: 0 1.6rem;
}

#intro {
    color: var(--main-color);
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.6;
    margin-top: 1.8rem;
}

@media (pointer: coarse) {
    #intro {
        font-size: 1.3rem;
    }
}

#intro p.hi {
    margin-bottom: 0.8rem;
}

#intro a.name,
#intro a.name:visited {
    text-decoration: underline;
}

#intro a.name:hover {
    text-decoration-color: var(--highlight-color);
}

#intro img {
    clip-path: circle(75px at center);
}

#intro p {
    margin: 0rem;
}

.occasionally {
    position: relative;
    height: 2.8rem;
}

.occasion {
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    width: 0;
}

.occasion:nth-child(1) {
    animation: typingErasing 20s steps(30, end) infinite;
}

.occasion:nth-child(2) {
    animation: typingErasing 20s steps(30, end) infinite;
    animation-delay: 10s;
}

@keyframes typingErasing {

    0%,
    50% {
        /* 
        Start and half-point: 
        Sentence is fully hidden
        (mimics being erased or before typing starts) 
        */
        width: 0;
    }

    5%,
    25% {
        /*
        Sentence is being "typed out", 
        width increases to reveal the text 
        */
        width: 100%;
    }

    /* 
    25% to 30%: 
    Sentence is fully visible, allowing it to be read. 
    This part is meant to keep the sentence on screen
    before starting the erase effect, but it was missing in the initial setup.
    */

    30%,
    45% {
        /*
        Keeps the sentence fully visible. 
        This interval doesn't change the appearance 
        and waits until the sentence disappears at 50%.
        */
        width: 100%;
    }

    50%,
    100% {
        /*
        Resets the sentence to fully hidden,
        preparing for the next sentence or cycle.
        */
        width: 0;
    }
}

#links {
    margin-top: 1.2rem;
}

#links a {
    display: inline-block;
}

.fade-in {
    opacity: 0;
    transform: translateY(1.2rem);
    animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
