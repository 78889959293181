:root {
  --main-color: #111b30;
  --border-color: #f3f3f3;
  --highlight-color: #ff0;
}

body {
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  font-family: Inter, Helvetica, sans-serif;
}

::selection {
  background-color: var(--highlight-color);
}

p a, p a:visited {
  color: var(--main-color);
  text-decoration: none;
}

p a:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: var(--highlight-color);
  text-decoration-color: var(--highlight-color);
}

hr {
  border: .06rem solid var(--border-color);
}

#content {
  margin: 0 1.6rem;
}

#intro {
  color: var(--main-color);
  margin-top: 1.8rem;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.6;
}

@media (pointer: coarse) {
  #intro {
    font-size: 1.3rem;
  }
}

#intro p.hi {
  margin-bottom: .8rem;
}

#intro a.name, #intro a.name:visited {
  text-decoration: underline;
}

#intro a.name:hover {
  -webkit-text-decoration-color: var(--highlight-color);
  text-decoration-color: var(--highlight-color);
}

#intro img {
  clip-path: circle(75px);
}

#intro p {
  margin: 0;
}

.occasionally {
  height: 2.8rem;
  position: relative;
}

.occasion {
  white-space: nowrap;
  width: 0;
  position: absolute;
  overflow: hidden;
}

.occasion:first-child {
  animation: 20s steps(30, end) infinite typingErasing;
}

.occasion:nth-child(2) {
  animation: 20s steps(30, end) 10s infinite typingErasing;
}

@keyframes typingErasing {
  0%, 50% {
    width: 0;
  }

  5%, 25% {
    width: 100%;
  }

  30%, 45% {
    width: 100%;
  }

  50%, 100% {
    width: 0;
  }
}

#links {
  margin-top: 1.2rem;
}

#links a {
  display: inline-block;
}

.fade-in {
  opacity: 0;
  animation: 1s ease-in-out forwards fadeIn;
  transform: translateY(1.2rem);
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/*# sourceMappingURL=index.9abdd132.css.map */
